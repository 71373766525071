import React from "react";
import {
  StyledHiveTechCrowdSectionHeader,
  StyledHiveTechCrowdSectionHeaderTitle,
  StyledHiveTechCrowdSectionHeaderSubtitle,
} from "./style.jsx";

export default ({
  id,
  title,
  subtitle,
}) => {
  return (
    <StyledHiveTechCrowdSectionHeader id={id}>
      <StyledHiveTechCrowdSectionHeaderTitle dangerouslySetInnerHTML={{ __html: title }}/>
      {subtitle && (
        <StyledHiveTechCrowdSectionHeaderSubtitle dangerouslySetInnerHTML={{ __html: subtitle }}/>
      )}
    </StyledHiveTechCrowdSectionHeader>
  );
};
